{
  // MENU HEADER
  $("header #nav-menu>ul>li, #operating-markets li").click(function (e) {
    if ($(this).hasClass("no-submenu")) {
      return;
    }

    $("header #nav-menu>ul>li, #operating-markets li")
      .not(this)
      .removeClass("active");
    $(this).toggleClass("active");

    if ($("header #nav-menu>ul>li").hasClass("active")) {
      $("header").addClass("active");
    } else {
      $("header").removeClass("active");
    }
  });
}
{
  $("#hamburguer").on("click", function () {
    $(this).toggleClass("active-hamburguer");
    $("#top").toggleClass("active-hamburguer");
    $("header").toggleClass("active");
  });
}
{
  // SCROLLTOP
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 50) {
      $("#top").addClass("fixed");
    } else {
      $("#top").removeClass("fixed");
    }
  });
}
{
  // SLIDE
  $(
    "#to-transform ul, #slider-our-history ul, #sust-hero ul, .development-programs ul"
  ).on("init", function (event, slick) {
    $(this)
      .find(".slider-paging-number li")
      .html(`<span>01</span> / 0${slick.slideCount}`);
  });

  $(
    "#to-transform ul, #slider-our-history ul, #sust-hero ul, .development-programs ul"
  ).slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    dots: true,
    adaptiveHeight: true,
    dotsClass: "slider-paging-number",
  });

  $(
    "#to-transform ul, #slider-our-history ul, #sust-hero ul, .development-programs ul"
  ).on("afterChange", function (event, slick, currentSlide) {
    $(this)
      .find(".slider-paging-number li")
      .html(`<span>0${currentSlide + 1}</span> / 0${slick.slideCount}`);
  });
}

{
  // SLIDE terminal
  $("#slider-terminal ul").on("init", function (event, slick) {
    $(this)
      .find(".slider-paging-number li")
      .html(`<span>01</span> / 0${slick.slideCount}`);
  });

  $("#slider-terminal ul").slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    dots: true,
    dotsClass: "slider-paging-number",
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $("#slider-terminal ul").on(
    "afterChange",
    function (event, slick, currentSlide) {
      $(this)
        .find(".slider-paging-number li")
        .html(`<span>0${currentSlide + 1}</span> / 0${slick.slideCount}`);
    }
  );
}

{
  let currentPage = 1;
  $("#load-more").on("click", function () {
    currentPage++; // Do currentPage + 1, because we want to load the next page

    jQuery.ajax({
      type: "POST",
      url: "/wp-admin/admin-ajax.php",
      dataType: "json",
      data: {
        action: "news_load_more",
        paged: currentPage,
      },
      beforeSend: function (xhr) {
        $("#load-more").text("Carregando...");
      },
      success: function (res) {
        if (currentPage >= res.max) {
          $("#load-more").hide();
        }
        $("#load-more").text("Carregar Mais");
        $("#other-news .posts").append(res.html);
      },
    });
  });
}
{
  $(".anchor-menu a").on("click", function (e) {
    e.preventDefault();
    var id = $(this).attr("href"),
      targetOffset = $(id).offset().top;

    window.scrollTo({ top: targetOffset - 150, behavior: "smooth" });
  });
}
{
  const selectCertificate = document.querySelectorAll("#downloadFile");

  if (selectCertificate) {
    selectCertificate.forEach((item) => {
      item.addEventListener("change", function () {
        let link = $(item).closest(".form-group").find(".btn");
        console.log(item.options[item.selectedIndex].value);
        $(link).attr("href", item.options[item.selectedIndex].value);
        //$(link).attr("download", item.options[item.selectedIndex].value);
      });
    });
  }
}
{
  // Modal
  $(".modal-body, .modal-text .close-button").on("click", function () {
    $(".modal-body, .modal-text").hide();
  });

  $(".show-modal").on("click", function () {
    $(".modal-text").show();
  });
}
{
  let options = {
    altInput: true,
    altFormat: "j/m/Y",
    dateFormat: "Y-m-d",
    position: "bellow",
    nextMonthNav: false,
    disableMobile: "true",
    locale: "pt",
    allowInput: true,
    onOpen: function (selectedDates, dateStr, instance) {
      $(instance.altInput).prop("readonly", true);
    },
    onClose: function (selectedDates, dateStr, instance) {
      $(instance.altInput).prop("readonly", false);
      $(instance.altInput).blur();
    },
  };
  flatpickr(".datepick", options);
}
